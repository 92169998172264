
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(2);
const sortBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "3",
  search: ""
});

export default defineComponent({
  name: "exam-general",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExam?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.orderBy +
            "&category=" +
            filterForm.value.category +
            "&status=2"  +
             "&keyWord=" +
             filterForm.value.search+
             "&examType=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          console.log("getExam");
          console.log(response.data.data.content);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
          setCurrentPageBreadcrumbs( "แบบฝึกหัด", []);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      // console.log(pageEvent);
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
      searchData,
    };
  },
  methods: {
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    /* eslint-disable */
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#FFE454'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#42FB91'>จบหลักสูตร</span>";
      } else if (status == 4) {
        return "<span style='color:#F998A2'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#ECAE51'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
    examOpen(id) {
      if (localStorage.getItem("id_token") != null) {
        this.$router.push({ name: "exam-general-detail", params: { id: id } });
      } else {
        this.$router.push({ name: "sign-in"});
      }
    },
  },
  async mounted() {
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=exam",
      { headers: {
        token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
      } }
    );
    console.log("category" ,  category.data.data);
    this.categoryList = category.data.data;
  },
  
});
